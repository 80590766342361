
             
@import '../styles/variables';
.f-panel {
    height: 100%;
    .f-panel__title {
        position: absolute;
        z-index: 100;
        top: -@component-padding;
    }
    .f-panel__border {
        height: 100%;
        padding: 4px @component-padding @component-padding;
    }
    &&.f-panel--inner-title {
        .f-panel__title {
            margin-left: 12px;
            position: initial;
        }
    }
}
