
             
.f-file-field {
    cursor: pointer;
    input[type=text] {
        cursor: pointer;
    }
    input[type=file] {
        display: none;
    }
    .v-text-field__slot {
        cursor: pointer;
    }
}
