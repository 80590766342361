.fude-icon-field .icon-column {
  flex-grow: 0;
}
.fude-icon-field .icon-column .v-image {
  cursor: pointer;
}
.fude-icon-field .icon-column .v-card {
  padding: 5px;
  margin-bottom: 27px;
}
.fude-icon-field .text-column {
  flex-grow: 1;
  align-self: flex-end;
  margin-right: 10px;
}
.fude-icon-field input[type=file] {
  display: none;
}
.fude-icon-field.fude-icon-field.fude-icon-field--hide-text .text-column {
  display: none;
}
.fude-icon-field.fude-icon-field.fude-icon-field--hide-text .icon-column {
  margin: auto;
}
.fude-icon-field.fude-icon-field.fude-icon-field--hide-text .icon-column .v-card {
  padding: 5px;
  margin: 0;
}
