.f-image-field {
  padding: 10px;
  cursor: pointer;
}
.f-image-field .f-image-field__image {
  margin: auto;
}
.f-image-field .f-image-field__file {
  display: none;
}
