
             
@import "../../../styles/variables";

.f-image-field {
    padding: @component-padding;
    cursor: pointer;
    .f-image-field__image {
        margin: auto;
    }
    .f-image-field__file {
        display: none;
    }
}
