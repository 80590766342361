
             
.f-search-panel {
    display: flex!important;
    height: 100%;
    .f-search-panel__input {
        padding-top: 0;
    }
}

