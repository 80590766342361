
             
.fude-icon-field {
    .icon-column {
        flex-grow: 0;
        .v-image {
            cursor: pointer;
        }
        .v-card {
            padding: 5px;
            margin-bottom: 27px;
        }
    }
    .text-column {
        flex-grow: 1;
        align-self: flex-end;
        margin-right: 10px;
    }
    input[type=file] {
        display: none;
    }

    &&.fude-icon-field--hide-text {
        .text-column {
            display: none;
        }
        .icon-column {
            margin: auto;
            .v-card {
                padding: 5px;
                margin: 0;
            }
        }
    }
}
