
             
@import "../../../styles/variables";
.f-data-iterator {
    display: flex!important;
    flex-direction: column;
    height: 100%;
    min-height: 100%;
    .f-data-iterator__header {
        display: flex;
        flex-grow: 0;
        .f-data-iterator__input {
            padding-top: 0;
            flex-grow: 0;
        }
    }
    .f-data-iterator__error {
        display: flex;
        flex-grow: 0;
        .v-alert {
            width: 100%;
        }
    }
    .f-data-iterator__content {
        display: flex;
        flex-grow: 1;
    }
    .f-data-iterator__footer {
        display: flex;
        flex-grow: 0;
        align-self: center;
        padding: @component-padding;
    }
}
