.f-file-field {
  cursor: pointer;
}
.f-file-field input[type=text] {
  cursor: pointer;
}
.f-file-field input[type=file] {
  display: none;
}
.f-file-field .v-text-field__slot {
  cursor: pointer;
}
