.f-panel {
  height: 100%;
}
.f-panel .f-panel__title {
  position: absolute;
  z-index: 100;
  top: -10px;
}
.f-panel .f-panel__border {
  height: 100%;
  padding: 4px 10px 10px;
}
.f-panel.f-panel.f-panel--inner-title .f-panel__title {
  margin-left: 12px;
  position: initial;
}
